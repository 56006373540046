<template>
  <div class="card">
    <div class="card-body pt-0" v-if="isDataReady">
      <ListTable
        :table-data="userList"
        :enable-items-per-page-dropdown="true"
        @delete-user="onDeleteUser"
      >
      </ListTable>
    </div>
    <div v-else>
      <TableSkeleton></TableSkeleton>
    </div>
  </div>
  <NewApplicationUserModal
    :displayModal="displayModal"
  ></NewApplicationUserModal>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  ComputedRef,
} from "vue";
import ListTable from "@/components/datatable/ListTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { ApplicationUser } from "@/core/types/UserTypes";
import { useStore } from "vuex";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import NewApplicationUserModal from "@/components/modals/forms/NewApplicationUserModal.vue";

export default defineComponent({
  name: "application-user-listing",
  components: {
    ListTable,
    NewApplicationUserModal,
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);

    const userList: ComputedRef<Array<ApplicationUser>> = computed(() => {
      return store.getters.applicationUsersList;
    });
    const tableData = ref<Array<ApplicationUser>>([]);

    store
      .dispatch(Actions.GET_APPLICATION_USERS, true)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving users.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onMounted(() => {
      setCurrentPageTitle("Application Users");
      setCurrentPageBreadcrumbs("User Accounts", ["Admin"]);
    });

    function openModal() {
      displayModal.value = true;
    }

    //::Begin Emit
    function onDeleteUser(id) {
      Swal.fire({
        title: "Are you sure you want to deactivate this account?",
        showCancelButton: true,
        confirmButtonText: "Yes. Deactivate",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_USER, id)
            .then(() => {
              Swal.fire("The account has been deactivated!", "", "success");
              for (let i = 0; i < tableData.value.length; i++) {
                if (tableData.value[i].id === id) {
                  tableData.value[i].archive === true;
                  //tableData.value.splice(i, 1);
                }
              }
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText:
                  "There was a problem deactivating the account.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    }
    //::End Emit

    watch(userList, () => {
      isDataReady.value = true;
    });

    return {
      isDataReady,
      tableData,
      userList,
      displayModal,
      onDeleteUser,
      openModal,
    };
  },
});
</script>
